import React from 'react';
import { Link } from "react-router-dom";

const LanguageOptionsBusiness = ({company, title, content1, content2}) => {

  const renderContentWithLineBreaks = (content) => {
    return content.map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };


  return (
    <div className="grow p-4 mx-auto rounded-lg flex flex-col flex-wrap ">
      <div className="font-semibold text-gray-400 mb-4">
        <p>{company}</p>
        <p className = "text-gray-600 text-3xl">{title}</p>
      </div>

      {/* Question Prompt */}
      <div className="mb-6">
        <p className="text-lg  text-gray-700">
          {content1.title}<br/>
          {renderContentWithLineBreaks(content1.content)}
        </p>

        <p className="text-lg  text-gray-700 mt-8">
          {content2.title}<br/>
          {renderContentWithLineBreaks(content2.content)}
        </p>
      </div>



     <div className="flex items-center m-8">
          <Link className="text-center bg-purple-600 text-white py-2 px-6 rounded-lg text-lg w-full" to="/video-questions/create/091lodksewe048c3bb67261efd5901jd">Continue</Link>

      </div>
      <div className = " text-center">
      </div>
    </div>
  );
}

export default LanguageOptionsBusiness;
