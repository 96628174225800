import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';



const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Identify opportunities and profitable strategies for trading cryptocurrency options",
          "- Lead the design and development of systematic options trading strategies",
          "- Continuously review strategy performance looking for new opportunities and areas for improvement",
          "- Collaborate with the engineering team to implement and optimize trading algorithms",
          "- Stay updated on market trends and developments in the crypto options space",

    ]
}

const content2 = {
  title: "Required Skills And Qualifications:",
  content: [
            "- Deep understanding of option mechanics and option trading strategies",
            "- 3+ years' experience trading options at a hedge fund or prop trading firm",
            "- Experience developing systematic, algorithmic trading strategies",
            "- Proficiency in Python for research, backtesting, and strategy implementation",
            "- Familiarity with financial data analysis tools (e.g., pandas, numpy)",
            "- Entrepreneurial mindset with the ability to work independently and as part of a team",
            "- Excellent problem-solving skills and attention to detail",
            "- Strong analytical and quantitative skills",
            
    ]
}

const companyURL = "https://www.kbit.com/";


function AppAladinKbit() {
  const { state, setData } = useAppContext();
  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Singapore, Hong Kong, London, NYC, and San Diego" salary="$150K/yr - $250K/yr" company="Kbit" companyURL={companyURL} />
          <LanguageOptionsTraders company="Kbit" title="Quant Options Trader" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppAladinKbit;
