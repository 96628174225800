import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';



const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Monitor and maintain strict risk management discipline",
          "- Manage portfolio, actively monitor hedged leverage exposure",
          "- Contribute to daily market risk analysis and to generate your own trading ideas",
    ]
}

const content2 = {
  title: "Required Qualifications:",
  content: [
            "- Highly motivated, self-driven individual that thrives in a fast-paced competitive environment",
            "- Strong analytical skills with attention to risk management",
            "- Team-oriented mentality",
            "- A focused, entrepreneurial personality",
            "- A strong passion for trading and the U.S. stock market",
            "- Experience trading in a hedge fund or other proprietary trading business",
            "- Existing licenses desired but not required",
    ]
}

const companyURL = "https://t3trading.com/";


function AppAladin() {
  const { state, setData } = useAppContext();
  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$200K/yr - $250K/yr" company="T3 Trading Group" companyURL={companyURL} />
          <LanguageOptionsTraders company="T3 Trading Group" title="Experienced Trader" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppAladin;
