import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';

const content1 = {
  title: "Responsibilities:",
  content: [
          "- Serve as the Relationship Manager for the Data Provider Community, engaging with leading trading firms and exchanges globally.",
          "- Collaborate with Community Managers to boost engagement through event participation, enhancing social presence, and improving brand visibility.",
          "- Assist the technical integration team in fully onboarding data providers to the main network and broadening the supported asset classes.",
          "- Manage governance protocols, communicate models, collect feedback, and establish a system for tracking and rewarding contributions.",
          "- Monitor system performance.",
          "- Facilitate due diligence and onboarding for new participants on the network.",
    ]
}

const content2 = {
  title: "Desired Skills and Experience:",
  content: [
            "- A minimum of 2 years of experience in a finance or business development role within fintech startups (e.g., banks, exchanges, crypto trading).",
            "- Experience with trading firms, trading desks, buy/sell side trading, or investment banking is highly relevant.",
            "- General understanding of cryptocurrency, including wallet usage, sending/receiving tokens, tracking transactions on a block explorer, and staking.",
            "- Proven ability to excel in a remote work environment, with strong systems thinking and effective communication skills to stay connected with the broader team.",
            "- Understanding of financial planning, accounting, and efficient company operations.",
    ]
}

const companyURL="https://www.intelcia.com/";

function AppTraderDenys2() {
  const { state, setData } = useAppContext();

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="United States (Remote)" salary="$200K + Bonus + Tokens" company="Intelcia" companyURL={companyURL} />
          <LanguageOptionsTraders company="Intelcia" title="Relationship Manager - crypto" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppTraderDenys2;
