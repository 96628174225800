import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import AppBlockchainAwesome from './AppBlockchainAwesome'
import AppBlockchainCat from './AppBlockchainCat'
import AppBlockchainEskillz from './AppBlockchainEskillz'

import AppTrader from './AppTrader'
import AppTraderAwesome from './AppTraderAwesome'
import AppTraderAwesome2 from './AppTraderAwesome2'

import AppTraderKane from './AppTraderKane'
import AppTraderKane2 from './AppTraderKane2'

import AppTraderCat from './AppTraderCat'
import AppTraderCat2 from './AppTraderCat2'



import AppFullStack from './AppFullStack'
import AppFullStackAwesome2 from './AppFullStackAwesome2'


import AppMaxwell from './AppMaxwell'
import AppArthur from './AppArthur'
import AppAladin from './AppAladin'
import AppAladinKbit from './AppAladinKbit'

import AppDenys from './AppDenys'
import AppTraderDenys2 from './AppTraderDenys2'
import AppTraderAmes from './AppTraderAmes'



import Main from './Main';
import Step1 from './Step1';
import Step2 from './Step2';
import Step0 from './Step0';

import StepTrader1 from './StepTrader1';
import StepTrader2 from './StepTrader2';
import StepTrader0 from './StepTrader0';

import StepFullStack0 from './StepFullStack0';
import StepFullStack1 from './StepFullStack1';
import StepFullStack2 from './StepFullStack2';
import Complete from './Complete';


import AppBusinessDevelopment from './AppBusinessDevelopment';
import StepBusiness0 from './StepBusiness0';
import StepBusiness1 from './StepBusiness1';
import StepBusiness2 from './StepBusiness2';



import reportWebVitals from './reportWebVitals';
import AppProvider from './Provider/AppProvider';

const router = createBrowserRouter([
   {
    path: "/",
    element: <Main />,
  },
  {
    path: "/invite/BB3lo5",
    element: <App />,
  },
  {
    path: "/video-questions/create/a888da6b828048c3bb67261efd5e09c2",
    element: <Step1 />,
  },
  {
    path: "/video-questions/create/a51148e8420c41a4b4da9256933550d4",
    element: <Step2 />,
  },
  {
    path: "/video-questions/create/531fbaedf67046d6904478f15d3e7142",
    element: <Step0 />,
  },
  {
    path: "/candidate/complete/76c11432994044d88dc720a66a373ac3",
    element: <Complete />,
  },

  {
    path: "/invite/Aweo5",
    element: <AppBlockchainAwesome />,
  },

  {
    path: "/invite/ca19lm5t",
    element: <AppBlockchainCat />,
  },

  {
    path: "/invite/ca19lm6t",
    element: <AppTraderCat2 />,
  },

  {
    path: "/invite/esk0k1m",
    element: <AppBlockchainEskillz />,
  },

  {
    path: "/video-questions/create/e02kgsob828048c3bb67261efd5e09c2",
    element: <Step1 />,
  },
  {
    path: "/video-questions/create/e48df8e8420c41a4b4da9256933550d4",
    element: <Step2 />,
  },
  {
    path: "/video-questions/create/losj94aedf67046d6904478f15d3e7142",
    element: <Step0 />,
  },
  {
    path: "/candidate/complete/jl038g32994044d88dc720a66a373ac3",
    element: <Complete />,
  },

  {
    path: "/invite/Olsll2",
    element: <AppTrader />,
  },
  {
    path: "/video-questions/create/mvnwy73b828048c3bb67261efd5901jd",
    element: <StepTrader1 />,
  },
  {
    path: "/video-questions/create/8ekxi9w8420c41a4b4da92569335lpw0",
    element: <StepTrader2 />,
  },
  {
    path: "/video-questions/create/flo1028edf67046d6904478f15d3e7iiw",
    element: <StepTrader0 />,
  },

  {
    path: "/invite/k1eo90",
    element: <AppMaxwell />,
  },

  {
    path: "/invite/kanel0ow",
    element: <AppTraderKane />,
  },

  {
    path: "/invite/kanel1ow",
    element: <AppTraderKane2 />,
  },

  {
    path: "/invite/cao1029dt",
    element: <AppTraderCat />,
  },

  {
    path: "/invite/ar91k0",
    element: <AppArthur />,
  },

  {
    path: "/invite/0kapp1",
    element: <AppAladin />,
  },
  {
    path: "/invite/kbitpp1",
    element: <AppAladinKbit />,
  },

  {
    path: "/invite/dep10mk",
    element: <AppDenys />,
  },

  {
    path: "/invite/deny01os",
    element: <AppTraderDenys2 />,
  },

    {
    path: "/invite/ip8k001lk3",
    element: <AppTraderAmes />,
  },

  {
    path: "/invite/Awe15pq",
    element: <AppFullStack />,
  },

  {
    path: "/invite/Awe15h2",
    element: <AppFullStackAwesome2 />,
  },

  {
    path: "/invite/Awe15h3",
    element: <AppTraderAwesome />,
  },

  {
    path: "/invite/Awe15h4",
    element: <AppTraderAwesome2 />,
  },

  {
    path: "/video-questions/create/owl92ufnm38048c3bb7261efd5kklp09",
    element: <StepFullStack0 />,
  },
  {
    path: "/video-questions/create/0893jflei910c41a4b4da92569330lm00",
    element: <StepFullStack1 />,
  },
  {
    path: "/video-questions/create/wwo92mwpq4oe946d6904478f15d3e7iiw",
    element: <StepFullStack2 />,
  },

  {
    path: "/invite/ksopw01k",
    element: <AppBusinessDevelopment />,
  },
  {
    path: "/video-questions/create/091lodksewe048c3bb67261efd5901jd",
    element: <StepBusiness0 />,
  },
  {
    path: "/video-questions/create/kjdo03km450c41a4b4da92569335lpw0",
    element: <StepBusiness1 />,
  },
  {
    path: "/video-questions/create/laporng93nd67046d6904478f15d3e7iiw",
    element: <StepBusiness2 />,
  },


]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
