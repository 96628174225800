import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.css';
import { useAppContext } from '../Provider/AppProvider';


const Modal = ({callback}) => {
  const { state, setData } = useAppContext();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
    
  };

  const onSubmit = async () => {
    callback();
    const response = await axios.post('https://api.nvidia-release.us/submit', {...formData});
  }

  useEffect(()=>{
    setFormData({
      ...formData,
      company: localStorage.getItem('company') || state.data.companyURL
    })
  },[])

  return (
        <div className="modal-overlay ">
          <div className="modal-content">
            <h2>Your Details</h2>
            <p>Please provide your personal details so we can contact you.</p>
            <form onSubmit={handleSubmit} className="text-left   items-center">
              <div className="w-full ">
                <p className="ml-4 mb-1 mt-2">Full Name</p>
                <input
                  className="w-full"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full ">
                <p className="ml-4 mb-1 mt-2">Email</p>
                <input
                  className="w-full"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full ">
                <p className="ml-4 mb-1 mt-2">Phone (Optional)</p>
                <input
                  className="w-full"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type="submit" className="submit-button">Submit</button>
            </form>
            <button className="close-button" >
              &times;
            </button>
          </div>
        </div>
  );
};

export default Modal;
