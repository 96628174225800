import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptions from './components/LanguageOptions';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';


const content1 = {
  title: "Scope of Work",
  content: [
           "- Creation of a responsive, user-friendly website with an investor dashboard.",
           "- Features allowing investors to manage tokens and track real estate investments.",
           "- Implementation of smart contracts for secure transaction recording.",
           "- Enabling payments via cryptocurrencies (e.g., Ethereum, stablecoins).",
           "- Advanced security measures to protect user data and transactions.",
           "- Compliance with regulations such as AML/KYC for asset tokenization.",
           "- Connecting our platform with the tokenization infrastructure provided by the licensed partner.",
           "- Testing and deploying integrated functionalities.",
      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Proven experience in developing Web3 and blockchain-based websites.",
           "- Proficiency in smart contract development (e.g., Solidity, Ethereum).",
           "- Experience in integrating external APIs.",
           "- Strong focus on security and regulatory compliance.",
      ]
}

const companyURL = "https://binary-studio.com";


function AppBlockchainCat() {
  const { state, setData } = useAppContext();

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote, New York" salary="$120,000 - $180,000/Yr" company="Binary Studio" companyURL={companyURL} />
           <LanguageOptions company="Binary Studio" title="Blockchain Engineer" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppBlockchainCat;
