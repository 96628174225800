import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import StepFullStackBar from './components/StepFullStackBar';
import CameraElement from './components/CameraElement';
import ReactWebCam from './components/ReactWebCam';
import Loading from './components/Loading';
import Modal from './components/Modal';

import './progressbar.css';

function StepFullStack2() {

  const [showModal, setShowModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [status, setStatus] = useState('disable');
  const [attempt, setAttempt] = useState(4);
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const targetTime = Math.floor(Math.random() * 3) + 3;

  useEffect(() => {
    if (isRunning && count < targetTime) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsRunning(false);
    }
  }, [count, isRunning, targetTime]);


  useEffect(() => {
      if (seconds < 600) { // 10 minutes = 600 seconds
          const intervalId = setInterval(() => {
              setSeconds(prevSeconds => prevSeconds + 1);
          }, 1000);

          // Clear interval on component unmount
          return () => clearInterval(intervalId);
      }
  }, [seconds]);

  // Format time as mm:ss
  const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes.toString().padStart(1, '0')}m ${seconds.toString().padStart(1, '0')}s`;
  };



  const recordRetry = () => {
    if(attempt > 0){
      
      setStatus('enable');
    }
  
  }

  const recordStart = () => {
    setAttempt(attempt-1);
    setStatus('record');
  }

  const recordEnd = () => {
    setStatus('end');
  }


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      {
        
        isRunning?<Loading/>
        :
        <main className="py-12 grow">
          <StepFullStackBar index = {3} className = "px-12"/>
          <div className="flex flex-wrap justify-center mt-12">

            {/* Left Section: Question and Recording */}
            <div className="w-5/6 md:w-1/3  p-6 px-12 rounded-lg ">
              {/* Question Number */}
                <div className="font-semibold text-gray-400 flex my-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                  </svg>

                  Video Recording (Communication and Problem-Solving)
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2 inline-block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  We recommend taking no more than 10m 00s to complete this question. Your time: <span className="text-blue-600">{formatTime(seconds)}</span>
                </div>

              {/* Question Prompt */}
              <div className="mb-6">
                <p className="text-lg  text-gray-600">
                 Please record a short video (3–5 minutes) where you explain how you would troubleshoot and resolve an issue where a deployed web application (frontend and backend) is experiencing slow performance. What steps would you take to identify and fix the problem? Feel free to discuss any tools, techniques, or strategies you would use in this situation.
                </p>
              </div>

              {/* Record Button */}
              {
                status == "disable" &&
                 <button disabled className="bg-gray-300 text-gray-500 px-12 py-3 rounded-lg flex items-center justify-center w-full" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>

                  <span className="ml-2 font-semibold ">Record now</span>
                </button>
              }

              {
                status == "enable" &&
                 <button onClick={recordStart}  className="bg-blue-500 text-white px-12 py-3 rounded-lg flex items-center justify-center w-full" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>

                  <span className="ml-2 font-semibold ">Record now</span>
                </button>
              }

              {
                status == "record" &&
                 <button onClick={recordEnd}  className="bg-red-500 text-white px-12 py-3 rounded-lg flex items-center justify-center w-full" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>

                  <span className="ml-2 font-semibold ">Recording</span>
                </button>
              }

              {
                status == "end" &&
                <div className="flex space-x-12">
                  <button onClick={recordRetry}  className="bg-blue-300 text-white px-12 py-3 rounded-lg flex items-center justify-center w-full" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>


                    <span className="ml-2 font-semibold ">Retry</span>
                  </button>

                  <button onClick={()=>setShowModal(true)}  className="bg-blue-500 text-white px-12 py-3 rounded-lg flex items-center justify-center w-full" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                    </svg>


                    <span className="ml-2 font-semibold ">Submit</span>
                  </button>
                </div>
              }
              <div className="text-sm text-gray-500 flex justify-between mt-2">
                <div className = 'flex justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 inline-block mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                Answer time: 05:00</div>
                <div className = 'flex justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 inline-block mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                  </svg>

                {attempt} of 4 attempt(s) left</div>
              </div>
            </div>

            {/* Right Section: Camera/Microphone Blocked Info */}
            <CameraElement callback = {()=>setStatus('enable')}/>
          </div>
          {
            showModal? <Modal callback={()=>navigate('/candidate/complete/76c11432994044d88dc720a66a373ac3')}/>
        :<></>
          }
        </main>
      }
      
      <Footer />
    </div>
  );
}

export default StepFullStack2;
