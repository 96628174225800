import React from 'react';
import { Link } from "react-router-dom";
import './StepBar.css'

const StepFullStackBar = ({ className, index }) => {
  return (
    <div className={`step-bar-container flex items-center justify-between ${className}`}>
      <Link to="/video-questions/create/owl92ufnm38048c3bb7261efd5kklp09" className={`circle ${index == 1 ? "active-step" : "passed-step"}`}>1</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/0893jflei910c41a4b4da92569330lm00" className={`circle ${index == 2 ? "active-step" : index == 3? "passed-step" : ""}`}>2</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/wwo92mwpq4oe946d6904478f15d3e7iiw" className={`circle ${index == 3 ? "active-step" : ""}`}>3</Link>

    </div>
  );
};

export default StepFullStackBar;
