import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptions from './components/LanguageOptions';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';


const content1 = {
  title: "Responsibilities:",
  content: [
           "- Using industry best practices, we write and review high-assurance smart contract code, tests, invariants, and other deliverables for our protocol projects.",
           "- Contribute ideas to protocol design & architecture and make refinements during development.Collaborate effectively with client team members.",
           "- Embrace and contribute to our internal methodologies, coding standards, review methods, and other standard operating procedures.",
           "- Help evaluate/research other protocols/infrastructure we could integrate with",
           "- Collaborate with cross-functional teams, including researchers and product managers, to ensure seamless implementation of new features.",
           "- Actively participate in code and design reviews, providing constructive feedback to peers and maintaining high coding standards.",
           "- Participate in internal security audits emphasizing robust processes, continuous learning, and ownership.",
           "- Keep up-to-date with the latest industry trends, technologies, and best practices to ensure the continuous improvement of Aera’s platform.",
           "- Contribute to the creation of technical documentation and user guides.",
           "- Troubleshoot, diagnose, and resolve software defects, ensuring optimal system performance and reliability",
      ]
}

const content2 = {
  title: "Qualifications:",
  content: [
           "- 4+ years of professional experience in software development.",
           "- Proficient at writing code in Python, preferably, but open to other languages.",
           "- 2+ years of experience in Solidity smart contract development.",
           "- Understanding of blockchain and DeFi protocols, concepts, and best practices.",
           "- Experience with EVM based blockchain networks strongly preferred .",
           "- Comfortable learning about and contributing to complex DeFi protocols that may involve understanding MEV, trading, incentives, and mechanism design.",
           "- Strong communication skills (primarily written) and the ability to work collaboratively in a distributed team environment.",
           "- Excellent problem-solving and analytical skills.",
           "- Experience with version control systems (i.e., git).",
           "- Excitement to work in an early stage setting with significant uncertainty, evolving scope/requirements and many hats to wear"

      ]
}

const companyURL = "https://www.gauntlet.xyz/";


function AppBlockchainAwesome() {
  const { state, setData } = useAppContext();

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote, New York" salary="$135,000 - $200,000/y" company="Gauntlet" companyURL={companyURL} />
           <LanguageOptions company="Gauntlet" title="Smart Contracts Engineer: Team Aera" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppBlockchainAwesome;
