import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';


const content1 = {
  title: "Responsibilities: ",
  content: [
           "- Trade crypto and forex currency pairs with the firm's capital",
           "- Continuously improve your trading skills through training and mentorship",
           "- Research and analyze market data to make informed trading decisions",
           "- Manage risk through diligent trade management and risk management strategies",
           "- Operate from anywhere in the world with a high-speed internet connection",
      ]
}

const content2 = {
  title: "Qualifications:",
  content: [
           "- No prior experience is required, but a willingness to learn and an entrepreneurial spirit is essential",
           "- Strong motivation and drive to succeed as a trader",
           "- Willingness to develop a strong understanding of financial markets and risk management",
           "- Strong analytical skills and the ability to make quick decisions in a fast-paced environment",
           "- Ability to work in a fast-paced and mentally challenging environment",
           "- Bachelor’s degree in finance, economics, or a related field is preferred but not required",
      ]
}

const companyURL = "https://www.eagleseven.com/";


function AppTraderCat2() {
  const { state, setData } = useAppContext();

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote, New York" salary="$180,000 - $250,000/Yr" company="Eagle Seven" companyURL={companyURL} />
           <LanguageOptionsTraders company="Eagle Seven" title="Cryptocurrency Trader - Remote" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppTraderCat2;
