import React, {useEffect} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsFullStack from './components/LanguageOptionsFullStack';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';


const content1 = {
  title: "Responsibilities:",
  content: [
           "- Full-Stack Development: Build and maintain both the backend and frontend of the Aera protocol, working on smart contracts, APIs, and user interfaces. ",
           "- Frontend Development: Develop and maintain the frontend of the protocol using modern JavaScript frameworks (React, Vue.js) to ensure a seamless user experience for DeFi participants and DAO members interacting with Aera. ",
           "- Backend & API Design: Develop and integrate backend services and APIs to communicate with smart contracts, interact with other protocols, and provide data to the frontend. Experience with Python, Node.js, or Go is preferred for backend tasks.  ",
           "- DeFi Protocol Optimization: Work closely with researchers and blockchain engineers to optimize the smart contract logic, improve gas efficiency, and enhance the scalability of the protocol.",
           "- Cross-Functional Collaboration: Work with product managers, designers, and other engineers to define, scope, and deliver features for the platform. Collaborate with researchers to implement cutting-edge DeFi strategies and financial models.  ",
           "- Security and Audits: Implement best practices for smart contract security and ensure high-assurance code. Participate in internal code reviews, security audits, and quality assurance testing. ",
           "- Continuous Learning & Innovation: Stay up-to-date with the latest trends in blockchain technologies, DeFi protocols, and web3 development, contributing new ideas to improve the platform.  ",
           "- Documentation & User Guides: Contribute to technical documentation and create clear user-facing guides for interacting with the protocol and its features.",
      ]
}

const content2 = {
  title: "Qualifications:",
  content: [
           "- Strong experience with frontend technologies such as React.js, Vue.js, or other modern JavaScript frameworks.",
           "- Proficiency in backend technologies like Node.js, Python, or Go (familiarity with backend architecture for web3 apps is a plus).",
           "- Solid understanding of blockchain technologies and DeFi concepts, including Ethereum (or EVM-based networks), smart contract security, DeFi protocols, incentive mechanisms, and governance models.",
           "- Experience with web3 libraries like web3.js, ethers.js, The Graph, or other decentralized storage solutions.",
           "- Strong understanding of RESTful API design, integration with blockchain networks, and building data pipelines for decentralized applications.",
           "- Experience with version control systems (Git) and working in an agile development environment.",
           "- Excellent communication skills (both written and verbal), especially for collaborating in a remote-first team.",
           "- Ability to work autonomously and handle evolving scopes and requirements in a dynamic, fast-paced environment.",
           "- Experience with testing and debugging tools for both frontend and smart contracts, including unit testing, integration testing, and security audits."

      ]
}

const companyURL = "https://www.gauntlet.xyz/";

function AppFullStack() {
  const { state, setData } = useAppContext();

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary=" $135,000 + $200,000/Yr" company="Gauntlet" companyURL={companyURL} />
          <LanguageOptionsFullStack company="Gauntlet" title="📝 Full-Stack Engineer – DeFi Protocols (Remote)" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppFullStack;
