import React from 'react';
import { Link } from "react-router-dom";
import './StepBar.css'

const StepBar = ({ className, index }) => {
  return (
    <div className={`step-bar-container flex items-center justify-between ${className}`}>
      <Link to="/video-questions/create/531fbaedf67046d6904478f15d3e7142" className={`circle ${index == 1 ? "active-step" : "passed-step"}`}>1</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/a888da6b828048c3bb67261efd5e09c2" className={`circle ${index == 2 ? "active-step" : index == 3? "passed-step" : ""}`}>2</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/a51148e8420c41a4b4da9256933550d4" className={`circle ${index == 3 ? "active-step" : ""}`}>3</Link>

    </div>
  );
};

export default StepBar;
